<template>
    <div class="mainPage">
        <h1>{{title || 'none'}}</h1>
        <h1>{{id || 'none'}}</h1>
        <h1>{{urlName || 'none'}}</h1>
        <h1>{{subtitle || 'none'}}</h1>
        <h1>{{desription || 'none'}}</h1>

    </div>
</template>
<script>
// import searchRadio from "@/components/searchRadio.vue"
import {mapGetters} from 'vuex'

export default {
    name: 'mainPage',
    
    props: {
        url: String,
        item: Object,
    },

    components: {
    //    searchRadio,
    },

    data(){
        return {
            // selectedStream: null,
            // searchString: '',
            // findStationCount: 0,

            id: '',
            title: '',
            urlName: '',
            subtitle: '',
            description: '',

            tagDescription: 'desc',
            tagKeyword: 'key'


        }
    },

    computed: {
        // return this.$store.getters.allRadio
        ...mapGetters(["getRadioList"]),


    },

    methods: {
        setParameters(item){
            this.id = item.id
            this.title = item.title
            this.urlName = item.url
            this.subtitle = item.subtitle
            this.desription = item.desription
        }
    },

    mounted(){
        console.log(this.getRadioList);
        
        //* ПРИ ЗАГРУЗКЕ СТРАНИЦЫ 
        //* Проверить передан ITEM стрима или страница перезагружена == заход по url
        //* Если ITEM передан - параметры станции выгружаем из ITEM
        //* Если перезагрузка - параметры станции выгружаем из stora по URL
  
        //* Если станция не найдена - редирект на 404

        //* Проверяем...
        if ( typeof this.item !== 'undefined' ) {
            //* если ITEM передан 
            console.log(`ITEM радиостанции ПОЛУЧЕН`);
            console.log(this.item);
            this.setParameters(this.item)

        } else {
            //* если ITEM не передан - парсим url
            console.log(`ITEM радиостанции НЕ получен = ${this.item}`);

            //* парсим название станции из url
            let urlName = window.document.location.pathname.toLowerCase()
            if ( urlName[0] == '/' ) {
                this.urlName = urlName = urlName.slice(1)           
            } else this.urlName = urlName
            console.log(`urlName = ${urlName}`);

            //* получаем параметры по url
            let item = this.getRadioList.filter( (item) => {
                return item.url.toLowerCase() == this.urlName
            })
            console.log(item[0]);
            this.setParameters(item[0])
        }   
        
        //*
        // console.log(metaInfo);
        
    },

    watch: {

    },

    metaInfo() {
        return {
            title: `${this.title} - слушайте радио в онлайн эфире`,
            htmlAttrs: {
                lang: 'ru',
            },
            meta: [
                { name: 'description', content: `${this.title} - слушайте онлайн радио эфир в хорошем качестве и абсолютно бесплатно` },
                { name: 'keywords', content: `'${this.title} радио онлайн` },
            ],
        }
    },


}
</script>